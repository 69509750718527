import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createMngmGroup } from 'account/components/ssoManagement/groups/new-actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import TenantRolesTableFieldDefault from '../users/TenantRolesTableField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { getDomainId } from 'api-client/helpers'

const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

const columns = [{ id: 'username', label: 'Name' }]

export default function CreateUserGroupModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    users: [],
    roleAssignments: {},
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)

  const { update, updating, error, reset } = useUpdateAction(createMngmGroup)

  const submitForm = useCallback(async () => {
    const body = {
      name: params.name,
      description: params.description,
      roleAssignments: params.roleAssignments,
      users: params.users,
      domain_id: getDomainId() || 'default',
    }
    const { success } = await update(body)
    if (success) {
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.userManagement.groups.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create User Group`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      initialValues={defaultParams}
      submitTitle={`Create User Group`}
    >
      <>
        <FormFieldSection title="User Group Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
        </FormFieldSection>
        <FormFieldSection title="Tenants & Roles">
          <Text variant="body2">
            Specify what Tenants this Group should have access to, and what role the users will be
            assigned in each Tenant.
          </Text>
          <Progress loading={loadingTenants} message={'Loading Tenants...'}>
            <TenantRolesTableField
              // validations={tenantRolesValidations}
              id="roleAssignments"
              tenants={tenants}
              onChange={getParamsUpdater('roleAssignments')}
              value={params.roleAssignments}
            />
          </Progress>
        </FormFieldSection>
        <FormFieldSection title="Users">
          <Text variant="body2">Specify which Users belong to this Group.</Text>
          <ListTableField
            id="users"
            data={users}
            loading={loadingUsers}
            columns={columns}
            onChange={getParamsUpdater('users')}
            value={params.users}
            uniqueIdentifier="id"
            searchTargets={['username']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
