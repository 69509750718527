import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { routes } from 'core/utils/routes'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import SsoPage from './sso/SsoPage'
import GroupsListPage from './groups/GroupsListPage'
import DocumentMeta from 'core/components/DocumentMeta'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import PassiveHeaderLink from 'core/components/passive-header-link'
import { dashboardUrl } from 'app/constants'
import AddGroupPage from './groups/AddGroupPage'
import EditGroupPage from './groups/EditGroupPage'

export default function SsoManagementPage() {
  const [isSSOEnabled, setIsSSOEnabled] = useState(false)
  const { features } = useSelector<RootState, SessionState>(prop(sessionStoreKey))

  return (
    <>
      <DocumentMeta title="SSO Management" />
      <AddGroupPage />
      <EditGroupPage />
      <HeaderTitlePortal>
        <PassiveHeaderLink icon="chevron-left" text="Back to Dashboard" url={dashboardUrl} />
      </HeaderTitlePortal>
      <Tabs route={routes.sso.root}>
        <Tab value="configure" label="SSO">
          <SsoPage setIsSSOEnabled={setIsSSOEnabled} />
        </Tab>
        <Tab value="groups" label="SAML Groups">
          <GroupsListPage isSSOEnabled={isSSOEnabled} />
        </Tab>
      </Tabs>
    </>
  )
}
