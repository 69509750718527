import withProgress from 'core/components/progress/withProgress'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import Dropdown from 'core/elements/dropdown'
import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listTenants } from '../tenants/new-actions'
import { tenantsSelector } from '../tenants/selectors'
import { useSelector } from 'react-redux'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

// interface TenantsPicklistProps<V> extends Omit<MultiDropdownProps<V>, 'items'> {
//   name: string
//   label: string
// }
interface TenantsPicklistProps<V> extends Omit<MultiDropdownProps<V>, 'items'> {}

export default function TenantsPicklist<V>({
  loading,
  onChange,
  value,
  disabled,
  name = 'tenants',
  label = 'Tenants',
  compact = true,
  ...rest
}: TenantsPicklistProps<V>) {
  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)
  const options = useMemo(() => projectAs({ label: 'name', value: 'id' }, tenants), [tenants])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={compact}
      disabled={disabled}
      value={value}
      onChange={onChange}
      loading={loading || loadingTenants}
      items={options}
    />
  )
}
