import TextField from 'core/components/validatedForm/TextField'
import { List } from '@material-ui/core'
import Text from 'core/elements/Text'
import {
  hasMinLength,
  hasOneLowerChar,
  hasOneUpperChar,
  hasOneNumber,
  hasOneSpecialChar,
  requiredValidator,
  passwordValidator,
  specialChars,
} from 'core/utils/fieldValidators'
import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  field: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
  },
  eyeIcon: {
    color: theme.components.button.primary.background,
    position: 'relative',
    top: 14,
    cursor: 'pointer',
  },
}))

const CheckListItem = ({ children, checked }) => (
  <ListItem>
    <ListItemIcon>{checked ? <CheckIcon /> : <ClearIcon color="error" />}</ListItemIcon>
    <ListItemText primary={children} />
  </ListItem>
)

const UserPasswordField = ({
  value,
  id = 'password',
  label = 'Password',
  className = '',
  showPasswordRequirements = true,
  restrictPassword = true,
  onChange = null,
}: Props) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const passwordValidators = restrictPassword
    ? [requiredValidator, passwordValidator]
    : [requiredValidator]
  return (
    <>
      <div className={classes.field}>
        <TextField
          id={id}
          label={label}
          value={value}
          type={showPassword ? 'text' : 'password'}
          validations={passwordValidators}
          className={className}
          onChange={onChange}
        />
        <FontAwesomeIcon className={classes.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? 'eye-slash' : 'eye'}
        </FontAwesomeIcon>
      </div>
      {showPasswordRequirements && (
        <Text variant="body1" component="div">
          Password must contain the following:
          <List dense>
            <CheckListItem checked={hasMinLength(8)(value)}>
              At least 8 characters long
            </CheckListItem>
            <CheckListItem checked={hasOneLowerChar(value)}>1 Lowercase letter</CheckListItem>
            <CheckListItem checked={hasOneUpperChar(value)}>1 Uppercase letter</CheckListItem>
            <CheckListItem checked={hasOneNumber(value)}>1 Number</CheckListItem>
            <CheckListItem checked={hasOneSpecialChar(value)}>
              1 Special character - {specialChars}
            </CheckListItem>
          </List>
        </Text>
      )}
    </>
  )
}

interface Props {
  value: string
  id?: string
  label?: string
  className?: string
  showPasswordRequirements?: boolean
  restrictPassword?: boolean
  onChange?: (value) => void
}

export default UserPasswordField
