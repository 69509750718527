import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getComputeQuotasForUser, updateComputeQuotasForUser } from '../tenants/new-actions'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TenantsPicklist from '../common/TenantsPicklist'
import Progress from 'core/components/progress/Progress'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  checkboxRow: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: 16,
    alignItems: 'center',
  },
  checkbox: {
    position: 'relative',
    top: 14,
  },
  fields: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
}))

interface Props {
  rows: any[]
  onClose: () => void
}

const convertValue = (number, unit) => {
  if (unit === 'MiB') {
    if (number >= 1024) {
      const convertedNumber = number / 1024
      return convertValue(convertedNumber, 'GiB')
    } else {
      return { number: parseFloat(number.toFixed(2)), unit }
    }
  } else if (unit === 'GiB') {
    if (number >= 1024) {
      const convertedNumber = number / 1024
      return convertValue(convertedNumber, 'TiB')
    } else {
      return { number: parseFloat(number.toFixed(2)), unit }
    }
  } else if (unit === 'TiB') {
    return { number: parseFloat(number.toFixed(2)), unit }
  }
}

const WithUnlimited = ({ children, property, params, updateParams }) => {
  const classes = useStyles()
  const [originalValue, setOriginalValue] = useState(-1)
  useEffect(() => {
    if (params[property] !== '' && params[property] !== -1) {
      setOriginalValue(params[property])
    }
  }, [params[property]])
  return (
    <div className={classes.checkboxRow}>
      {children}
      <CheckboxField
        className={classes.checkbox}
        id={`${property}_unlimited`}
        label="Set Unlimited"
        value={params[property] === -1 ? true : false}
        onChange={(value) => {
          if (value) {
            updateParams({ [property]: -1 })
          } else {
            updateParams({ [property]: originalValue })
          }
        }}
      />
    </div>
  )
}

export default function UserQuotasModal({ rows: [user], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    tenant: null,
    cores: null,
    ram: null,
    instances: null,
    coresUsed: null,
    ramUsed: null,
    instancesUsed: null,
  }
  const [dataLoaded, setDataLoaded] = useState(true)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateComputeQuotasForUser)

  useEffect(() => {
    if (!params.tenant) {
      return
    }
    const makeCalls = async () => {
      const response = await getComputeQuotasForUser({ tenantId: params.tenant, userId: user.id })
      updateParams({
        cores: response?.cores?.limit,
        ram: response?.ram?.limit,
        instances: response?.instances?.limit,
        coresUsed: response?.cores?.in_use,
        ramUsed: response?.ram?.in_use,
        instancesUsed: response?.instances?.in_use,
      })
      setDataLoaded(true)
    }
    setDataLoaded(false)
    makeCalls()
  }, [params.tenant])

  const submitForm = useCallback(async () => {
    const body = {
      quota_set: {
        cores: params.cores,
        ram: params.ram,
        instances: params.instances,
      },
    }
    const { success } = await update({ tenantId: params.tenant, userId: user?.id, body })
    if (success) {
      handleClose()
    }
  }, [user?.id, params])

  const handleClose = () => {
    setParams(defaultParams)
    setDataLoaded(false)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Quotas for User ${user.username}`}
      onSubmit={submitForm}
      onClose={handleClose}
      // loading={!dataLoaded}
      submitting={updating}
      error={error}
      submitTitle={`Update User Quotas`}
      open
    >
      <FormFieldSection title="Compute Quotas">
        <Text variant="body2">
          User quotas are unique per tenant. User quotas may be set equal to or lower than the
          associated tenant's quota.
        </Text>
        <PicklistField
          DropdownComponent={TenantsPicklist}
          name="tenant"
          id="tenant"
          label="Tenant"
          compact={false}
          onChange={getParamsUpdater('tenant')}
          required
        />
        {!!params.tenant && (
          <Progress loading={!dataLoaded}>
            <div className={classes.fields}>
              <WithUnlimited property="cores" params={params} updateParams={updateParams}>
                <TextField
                  id="cores"
                  label="Cores"
                  onChange={getParamsUpdater('cores')}
                  value={params.cores}
                  type="number"
                  min={-1}
                  info={`Cores currently in use by user: ${params.coresUsed}`}
                  required
                />
              </WithUnlimited>
              <WithUnlimited property="ram" params={params} updateParams={updateParams}>
                <TextField
                  id="ram"
                  label="RAM (MiB)"
                  onChange={getParamsUpdater('ram')}
                  value={params.ram}
                  type="number"
                  min={-1}
                  info={`RAM currently in use by user: ${params.ramUsed} MiB`}
                  required
                />
              </WithUnlimited>
              <WithUnlimited property="instances" params={params} updateParams={updateParams}>
                <TextField
                  id="instances"
                  label="VMs"
                  onChange={getParamsUpdater('instances')}
                  value={params.instances}
                  type="number"
                  min={-1}
                  info={`VMs currently in use by user: ${params.instancesUsed}`}
                  required
                />
              </WithUnlimited>
            </div>
          </Progress>
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
