import React, { useCallback, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TenantRolesTableFieldDefault from '../../userManagement/users/TenantRolesTableField'
import { requiredValidator } from 'core/utils/fieldValidators'
import { listTenants } from '../../userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import {
  groupFormSubmission,
  loadIdpProtocols,
  listMngmGroupMappings,
  listMngmGroups,
} from './new-actions'
import GroupSettingsFields from './GroupSettingsFields'
import GroupCustomMappingsFields from './GroupCustomMappingsFields'
import GroupsTips from './GroupsTips'
import ModalForm from 'core/elements/modal/ModalForm'
import Column from 'core/containers/Column'
import { groupMappingsSelector, groupsSelector } from './selectors'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { noop } from 'utils/fp'
import { getDomainName } from 'api-client/helpers'
import { defaultGroupMappingId } from 'app/constants'

const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const initialContext = { customMappings: [], roleAssignments: {} }

const AddGroupPage = () => {
  const { history } = useReactRouter()
  const classes = useStyles({})

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { loading: loadingGroupMappings } = useListAction(listMngmGroupMappings)
  const groupMappings = useSelector(groupMappingsSelector)

  const { loading: loadingGroups } = useListAction(listMngmGroups)
  const groups = useSelector(groupsSelector)

  const [submitting, updateSubmitting] = useState(false)
  const [protocolExists, setProtocolExists] = useState(false)
  const existingMapping = groupMappings.find((m) => m.id === defaultGroupMappingId(getDomainName()))

  const submitForm = useCallback(
    async (params) => {
      updateSubmitting(true)
      await groupFormSubmission({
        params,
        existingMapping,
        operation: 'create',
        protocolExists,
      })
      updateSubmitting(false)
      history.push(routes.sso.root.path({ tab: 'groups' }))
    },
    [existingMapping, protocolExists],
  )

  // Check for existence of saml2 protocol
  useEffect(() => {
    const checkForProtocol = async () => {
      const protocols = await loadIdpProtocols()
      setProtocolExists(!!protocols.find((p) => p.id === 'saml2' && p.idp_id === getDomainName()))
    }
    checkForProtocol()
  }, [])

  return (
    <>
      <DocumentMeta title="Add Group" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.sso.addGroup}
        title="Add Group"
        initialValues={initialContext}
        onBackdropClick={noop}
        onClose={() => history.push(routes.sso.root.path({ tab: 'groups' }))}
        onSubmit={submitForm}
        submitTitle="Add Group"
        loading={loadingGroupMappings}
        submitting={submitting}
        loadingMessage={submitting ? 'Submitting form...' : 'Loading Group...'}
      >
        {({ setFieldValue, values }) => {
          return (
            <>
              <Column gap={16}>
                <GroupsTips />
                <FormFieldSection title="Group Settings" step={1}>
                  <Column gap={16}>
                    <GroupSettingsFields
                      setFieldValue={setFieldValue}
                      values={values}
                      groups={groups}
                    />
                  </Column>
                  <GroupCustomMappingsFields
                    id="customMappings"
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </FormFieldSection>
                <FormFieldSection title="Tenants & Roles" step={2}>
                  <Text variant="body2">
                    Specify what Tenants this Group should have access to, and what role the users
                    will be assigned in each Tenant.
                  </Text>
                  <Progress
                    renderContentOnMount={!loadingTenants}
                    loading={loadingTenants}
                    message={'Loading Tenants...'}
                  >
                    <TenantRolesTableField
                      validations={tenantRolesValidations}
                      id="roleAssignments"
                      tenants={tenants}
                    />
                  </Progress>
                </FormFieldSection>
              </Column>
            </>
          )
        }}
      </ModalForm>
    </>
  )
}

export default AddGroupPage
