import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createTenant } from './new-actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import UserRolesTableField from 'account/components/userManagement/tenants/UserRolesTableField'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export default function CreateTenantModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    roleAssignments: {},
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading } = useListAction(listUsers)
  const users = useSelector(usersSelector)
  const { reload: reloadTenants } = useListAction(listUserTenants)

  const { update, updating, error, reset } = useUpdateAction(createTenant)

  const submitForm = useCallback(async () => {
    const body = {
      name: params.name,
      description: params.description,
      roleAssignments: params.roleAssignments,
    }
    const { success } = await update(body)
    if (success) {
      reloadTenants(true, true)
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.userManagement.tenants.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Tenant`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      initialValues={defaultParams}
      submitTitle={`Create Tenant`}
    >
      <>
        <FormFieldSection title="Tenant Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
          <Progress renderContentOnMount={!loading} loading={loading} message={'Loading Users...'}>
            <UserRolesTableField
              // validations={userRolesValidations}
              id="roleAssignments"
              users={users}
              onChange={getParamsUpdater('roleAssignments')}
            />
          </Progress>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
