import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import DocumentMeta from 'core/components/DocumentMeta'
import SsoToggle from './SsoToggle'
import TextField from 'core/components/validatedForm/TextField'
import SsoProviderPicklist from './SsoProviderPicklist'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import UploadSamlMetadataLink from './UploadSamlMetadataLink'
import Button from 'core/elements/button'
import CopyToClipboard from 'core/components/CopyToClipboard'
import useParams from 'core/hooks/useParams'
import SubmitButton from 'core/components/buttons/SubmitButton'
import DropdownField from 'core/components/validatedForm/DropdownField'
import {
  createSsoConfig,
  deleteSsoConfig,
  loadSsoConfig,
  getIdps,
  addIdp,
  updateIdp,
} from './actions'
import Progress from 'core/components/progress/Progress'
import SsoEnabledDialog from './SsoEnabledDialog'
import { SsoProviders } from './model'
import AccountUpgradeDialog from '../../theme/AccountUpgradeDialog'
import { CustomerTiers, ssoEnabledTiers } from 'app/constants'
import { pathOr, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { trackEvent } from 'utils/tracking'
import { getDomainId, getDomainName } from 'api-client/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  wizardLabel: {
    margin: theme.spacing(1, 0),
  },
  fullWidth: {
    width: '100% !important',
  },
  spaceBelow: {
    marginBottom: theme.spacing(1.5),
  },
  conditionalFields: {
    borderTop: `1px solid ${theme.components.card.border}`,
    marginTop: 24,
    paddingTop: 16,
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  field: {
    display: 'flex',
    alignItems: 'center',
  },
  orLabel: {
    marginRight: 24,
  },
  attributeMapButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
  },
  copyIcon: {
    marginRight: theme.spacing(0.5),
  },
  copyButton: {
    marginLeft: theme.spacing(4),
  },
  filename: {
    marginLeft: theme.spacing(0.5),
  },
  error: {
    color: theme.palette.red[500],
  },
  card: {
    overflowY: 'auto',
    maxWidth: 'inherit',
  },
}))

type State = {
  enableSso: boolean
  ssoIsEnabled: boolean
  ssoProviderName: string
  defaultAttributeMap: string
  entityId: string
  ssoProvider: string
  metadataUrl?: string
  // metadata?: string
  // metadataFileName?: string
}

const customCodeMirrorOptions = {
  mode: 'xml',
}

const SsoPage = ({ setIsSSOEnabled }) => {
  const classes = useStyles({})
  const [loading, setLoading] = useState(true)
  const [dialogOpened, setDialogOpened] = useState(false)
  const [upgradeDialogOpened, setUpgradeDialogOpened] = useState(false)
  const [hasIdp, setHasIdp] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const { params, updateParams, getParamsUpdater } = useParams<State>({
    enableSso: false,
    ssoIsEnabled: false,
    defaultAttributeMap: '',
    entityId: '',
    ssoProvider: '',
    ssoProviderName: '',
  })

  // Set the parent state to true if SSO is enabled
  useEffect(() => {
    setIsSSOEnabled(params.ssoIsEnabled)
  }, [params.ssoIsEnabled])

  useEffect(() => {
    const getSettings = async () => {
      try {
        const {
          attr_map_xml: defaultAttributeMap,
          entity_id: entityId,
          provider,
          metadata_url: metadataUrl,
        }: any = await loadSsoConfig()

        // If SSO Provider is not one of the predefined ones, set it to Other
        // and set the name of the provider
        let ssoProvider = provider,
          ssoProviderName = ''
        if (![SsoProviders.Google, SsoProviders.Okta, SsoProviders.OneLogin].includes(provider)) {
          ssoProviderName = provider
          ssoProvider = SsoProviders.Other
        }

        updateParams({
          defaultAttributeMap,
          entityId,
          ssoProvider,
          ssoProviderName,
          metadataUrl,
          enableSso: true,
          ssoIsEnabled: true,
        })
      } catch (err) {
        console.log(err, 'error')
        updateParams({ ssoIsEnabled: false })
      }
      setLoading(false)
    }

    const loadIdps = async () => {
      const idps = await getIdps()
      const hasIDP = !!idps.find((idp) => idp.id === getDomainName())
      setHasIdp(hasIDP)
    }
    getSettings()
    loadIdps()
  }, [])

  const toggleSso = useCallback(async () => {
    if (params.enableSso && params.ssoIsEnabled) {
      await deleteSsoConfig()
      updateParams({ enableSso: false, ssoIsEnabled: false })
      return
    }
    updateParams({ enableSso: !params.enableSso })
  }, [params, updateParams, deleteSsoConfig, hasIdp])

  const updateSsoSettings = useCallback(async () => {
    // Validate that only metadataUrl or metadata is defined
    // if (params.metadataUrl && params.metadata) {
    //   return
    // }

    // If SSO config already exists, delete old one and create a new one
    // Otherwise just create a new one
    // Need to do this until backend provides an update method
    if (params.ssoIsEnabled) {
      await deleteSsoConfig()
    }

    const body = {
      provider:
        params.ssoProvider === SsoProviders.Other ? params.ssoProviderName : params.ssoProvider,
      entity_id: params.entityId,
      metadata_url: params.metadataUrl,
      // metadata: params.metadataUrl ? undefined : params.metadata,
      attr_map_xml: params.defaultAttributeMap,
      domain_name: getDomainName(),
    }
    setLoading(true)
    try {
      await createSsoConfig(body)
      if (hasIdp) {
        await updateIdp({
          identity_provider: {
            remote_ids: [params.entityId],
          },
        })
      } else {
        await addIdp({
          identity_provider: {
            remote_ids: [params.entityId],
            description: null,
            domain_id: getDomainId() || null,
            enabled: true,
          },
        })
      }
      updateParams({ ssoIsEnabled: true })
      // Show user a dialog saying sso configuration successful
      setDialogOpened(true)
    } finally {
      setLoading(false)
    }
  }, [params, hasIdp])

  return (
    <>
      <DocumentMeta title="SSO Management" bodyClasses={['form-view']} />
      {upgradeDialogOpened && (
        <AccountUpgradeDialog
          feature="Enterprise SSO"
          onClose={() => setUpgradeDialogOpened(false)}
        />
      )}
      <Progress loading={loading}>
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          elevated={false}
          formActions={<>{params.enableSso && <SubmitButton>Save</SubmitButton>}</>}
          onSubmit={() => updateSsoSettings()}
        >
          <FormFieldCard className={classes.card}>
            <SsoToggle
              ssoIsEnabled={params.ssoIsEnabled}
              checked={params.enableSso}
              onClick={toggleSso}
            />
            <Text variant="body2" className={classes.spaceBelow}>
              Enterprise Single Sign On supports SAML 2.0 identity integration for seamless access
              to your Platform9 instance.
            </Text>
            {params.enableSso && (
              <div className={classes.conditionalFields}>
                <DropdownField
                  DropdownComponent={SsoProviderPicklist}
                  id="ssoProvider"
                  label="SSO Provider"
                  onChange={getParamsUpdater('ssoProvider')}
                  value={params.ssoProvider}
                  required
                />
                {params.ssoProvider === SsoProviders.Other && (
                  <TextField
                    id="ssoProviderName"
                    label="SSO Provider Name"
                    onChange={getParamsUpdater('ssoProviderName')}
                    value={params.ssoProviderName}
                    info="Provide a name to identify your SSO provider"
                    required
                  />
                )}
                <Text variant="caption1" className={classes.wizardLabel}>
                  Entity Endpoint for your SSO Provider
                </Text>
                <TextField
                  id="entityId"
                  label="Entity ID"
                  onChange={getParamsUpdater('entityId')}
                  value={params.entityId}
                  required
                />
                {/* <Text variant="caption1" className={classes.wizardLabel}>
                  SAML Metadata (XML) from your SSO Provider
                  <Text variant="body2">
                    You may specify a metadata URL or upload a metadata XML file.
                  </Text>
                </Text> */}
                <TextField
                  id="metadataUrl"
                  label="SAML Metadata URL"
                  onChange={getParamsUpdater('metadataUrl')}
                  value={params.metadataUrl}
                  required
                  // required={!params.metadata}
                />
                {/* <div className={classes.field}>
                  <Text variant="body2" className={classes.orLabel}>
                    OR
                  </Text>
                  <UploadSamlMetadataLink
                    id="metadata"
                    onChange={getParamsUpdater('metadata')}
                    fileNameUpdater={getParamsUpdater('metadataFileName')}
                  />
                  <Text variant="body2" className={classes.filename}>
                    {params.metadataFileName}
                  </Text>
                </div>
                <CodeMirror
                  id="metadata"
                  label="SAML Metadata (XML)"
                  options={customCodeMirrorOptions}
                  onChange={getParamsUpdater('metadata')}
                  value={params.metadata}
                  className={classes.fullWidth}
                  required={!params.metadataUrl}
                />
                {params.metadataUrl && params.metadata && (
                  <Text variant="body2" className={classes.error}>
                    Please specify only the metadata URL or only the metadata XML
                  </Text>
                )} */}
                <Text variant="caption1" className={classes.wizardLabel}>
                  SSO Provider Attribute MAP in XML
                </Text>
                <Text variant="body2">
                  Enterprise Single Sign on supports SAML 2.0 identity integration for seamless
                  access to your Platform9 instance.
                </Text>
                <CodeMirror
                  id="defaultAttributeMap"
                  label="Default Attribute Map"
                  options={customCodeMirrorOptions}
                  onChange={getParamsUpdater('defaultAttributeMap')}
                  value={params.defaultAttributeMap}
                  className={classes.fullWidth}
                  required
                />
                <div className={classes.attributeMapButtons}>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => updateParams({ defaultAttributeMap: '' })}
                  >
                    Clear XML
                  </Button>
                  <CopyToClipboard
                    copyText={params.defaultAttributeMap}
                    copyIcon={false}
                    inline={false}
                    triggerWithChild
                  >
                    {
                      // @ts-ignore
                      <Button type="button" icon="copy">
                        Copy Attribute Map
                      </Button>
                    }
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </FormFieldCard>
          {dialogOpened && <SsoEnabledDialog onClose={() => setDialogOpened(false)} />}
        </ValidatedForm>
      </Progress>
    </>
  )
}

export default SsoPage
