import { createSelector } from '@reduxjs/toolkit'
import {
  always,
  filter,
  find,
  flatten,
  groupBy,
  head,
  innerJoin,
  isNil,
  map,
  omit,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
  uniq,
  values,
  when,
} from 'ramda'
import { emptyObj, upsertAllBy } from 'utils/fp'
import { castBoolToStr } from 'utils/misc'
import {
  allTenantsSelector,
  filterValidTenants,
} from 'account/components/userManagement/tenants/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { Tenant, TenantUser } from 'api-client/keystone.model'
import { IUsersSelector } from './model'
import { isSystemUser } from './helpers'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { SortConfig } from 'core/helpers/createSorter'

const adminUserNames = ['heatadmin', 'admin@platform9.net', 'discovery', 'hagrid']

interface PluckedTenantUsers extends TenantUser {
  tenantId: string
}

type UnifiedTenantUsers = Omit<TenantUser, 'tenantId'> & { tenants: Tenant[] }

export const credentialsSelector = getDataSelector<DataKeys.ManagementCredentials>(
  DataKeys.ManagementCredentials,
)

export const basicUsersSelector = createSharedSelector(
  getDataSelector<DataKeys.ManagementUsers>(DataKeys.ManagementUsers),
  (users) => {
    return users
  },
)

export const basicUsersByIdSelector = createSharedSelector(basicUsersSelector, (users) => {
  return users.reduce((accum, user) => {
    return {
      ...accum,
      [user.id]: user,
    }
  }, {})
})

export const usersSelector = createSharedSelector(
  getDataSelector<DataKeys.ManagementUsers>(DataKeys.ManagementUsers),
  getDataSelector<DataKeys.ManagementCredentials>(DataKeys.ManagementCredentials),
  allTenantsSelector,
  (rawUsers, credentials, allTenants) => {
    // Disable this for now, to be removed (all tenants all users no longer an API)
    // return []
    // Don't return any users until tenants load and we can determine which are invalid
    if (!allTenants.length) return []

    const validTenants = filter<Tenant>(filterValidTenants, allTenants)

    /*
      Find tenant ids that are system tenants and reject any user with:
        - A UUID as a name
        - An empty name
        - Has an admin username
        - Is assigned to a system tenant
    */
    const filterSystemUsers = (user: IUsersSelector) => {
      const blacklistedTenantIds = pluck('id', reject<Tenant>(filterValidTenants, allTenants))
      return (
        !isSystemUser(user) &&
        user.username &&
        !adminUserNames.includes(user.username) &&
        !blacklistedTenantIds.includes(user.defaultProject)
      )
    }

    // Get all tenant users and assign their corresponding tenant ID
    // const pluckUsers = map<Tenant, PluckedTenantUsers[]>((tenant) =>
    //   tenant.users.map((user) => ({
    //     ...user,
    //     tenantId: tenant.id,
    //   })),
    // )

    // Unify all users with the same ID and group the tenants
    // const unifyTenantUsers = map<PluckedTenantUsers[], UnifiedTenantUsers>(
    //   (groupedUsers: PluckedTenantUsers[]) => ({
    //     ...omit<PluckedTenantUsers, 'tenantId'>(['tenantId'], head(groupedUsers)),
    //     tenants: innerJoin(
    //       (tenant, id) => tenant.id === id,
    //       validTenants,
    //       uniq(pluck<'tenantId', PluckedTenantUsers>('tenantId', groupedUsers)),
    //     ),
    //   }),
    // )

    const allUsers = rawUsers.map((user) => ({
      ...user,
      enabled: !!user.enabled,
      id: user.id,
      username: user.name,
      displayname: user.displayname,
      email: user.email || user.name,
      defaultProject: user.default_project_id,
      options: user.options,
      isLocal: user.is_local,
      twoFactor: pipe(
        find(propEq('user_id', user.id)),
        when(isNil, always(emptyObj)),
        propEq('type', 'totp'),
        castBoolToStr('enabled', 'disabled'),
      )(credentials),
      mfa: {
        enabled: false,
      },
    }))
    return allUsers.filter(filterSystemUsers)

    // return pipe<
    //   Tenant[],
    //   PluckedTenantUsers[][],
    //   PluckedTenantUsers[],
    //   {
    //     [index: string]: PluckedTenantUsers[]
    //   },
    //   PluckedTenantUsers[][],
    //   UnifiedTenantUsers[],
    //   IUsersSelector[],
    //   IUsersSelector[]
    // >(
    //   // pluckUsers,
    //   flatten,
    //   groupBy(prop('id')),
    //   values,
    //   unifyTenantUsers,
    //   upsertAllBy(prop('id'), allUsers),
    //   filter(filterSystemUsers),
    // )(validTenants)
  },
)

export const makeFilteredUsersSelector = (
  defaultParams = {} as SortConfig & { systemUsers?: boolean },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(
    usersSelector,
    allTenantsSelector,
    selectParams,
    (users, allTenants, params) => {
      const { systemUsers } = params
      const blacklistedTenants = reject(filterValidTenants, allTenants)
      const blacklistedTenantIds = pluck('id', blacklistedTenants)
      const filterUsers = filter<IUsersSelector>((user) => {
        return (
          (systemUsers || !isSystemUser(user)) &&
          user.username &&
          !adminUserNames.includes(user.username) &&
          !blacklistedTenantIds.includes(user.defaultProject)
        )
      })
      return pipe<IUsersSelector[], IUsersSelector[]>(filterUsers)(users)
    },
  )
}

export const userRolesSelector = getDataSelector<DataKeys.ManagementUsersRoleAssignments>(
  DataKeys.ManagementUsersRoleAssignments,
  ['userId'],
)
