import ApiClient from 'api-client/ApiClient'

const { hagrid, keystone } = ApiClient.getInstance()

export const getIdps = async () => keystone.getIdentityProviders()

export const addIdp = async (body) => keystone.addIdentityProvider(body)

export const updateIdp = async (body) => keystone.updateIdentityProvider(body)

export const getSsoDetails = async () => hagrid.getSsoDetails()

export const loadSsoConfig = async () => hagrid.getSsoConfig()

export const createSsoConfig = async (config) => hagrid.createSsoConfig(config)

export const deleteSsoConfig = async () => hagrid.deleteSsoConfig()
