import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { pathStr } from 'utils/fp'
import UserRolesTableField from 'account/components/userManagement/tenants/UserRolesTableField'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import { getTenantRoleAssignments, updateTenant } from './new-actions'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditTenantModal({ rows: [tenant], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    name: '',
    description: '',
    roleAssignments: {},
  }
  const [roleAssignments, setRoleAssignments] = useState([])
  const [loadingRoleAssignments, setLoadingRoleAssignments] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)
  const { reload: reloadTenants } = useListAction(listUserTenants)

  const { update, updating, error, reset } = useUpdateAction(updateTenant)

  useEffect(() => {
    const makeCalls = async () => {
      const response = await getTenantRoleAssignments({ tenantId: tenant.id })
      setRoleAssignments(response)
      setLoadingRoleAssignments(false)
    }
    makeCalls()
  }, [])

  useEffect(() => {
    if (loadingRoleAssignments) {
      return
    }
    const initialParams = {
      id: tenant.id,
      name: tenant.name,
      description: tenant.description || '',
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('user.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }
    updateParams(initialParams)
    setDataLoaded(true)
  }, [tenant, roleAssignments, loadingRoleAssignments])

  const initialContext = useMemo(() => {
    return {
      id: tenant.id,
      name: tenant.name,
      description: tenant.description || '',
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('user.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }
  }, [tenant, roleAssignments, loadingRoleAssignments])

  const loadingSomething = loadingUsers || loadingRoleAssignments || !dataLoaded

  const submitForm = useCallback(async () => {
    const body = {
      id: tenant?.id,
      name: params.name,
      description: params.description,
      roleAssignments: params.roleAssignments,
    }
    const { success } = await update(body)
    if (success) {
      reloadTenants(true, true)
      handleClose()
    }
  }, [tenant?.id, params])

  const handleClose = () => {
    setParams(defaultParams)
    setDataLoaded(false)
    setLoadingRoleAssignments(true)
    setRoleAssignments([])
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Tenant`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={loadingSomething}
      submitting={updating}
      error={error}
      initialValues={initialContext}
      submitTitle={`Update Tenant`}
      open
    >
      <>
        <FormFieldSection title="Tenant Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            disabled={tenant?.name === 'service'}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
            disabled={tenant?.name === 'service'}
          />
          <Progress
            renderContentOnMount={!loadingSomething}
            loading={loadingSomething}
            message={'Loading Users...'}
          >
            <UserRolesTableField
              // validations={userRolesValidations}
              id="roleAssignments"
              users={users}
              onChange={getParamsUpdater('roleAssignments')}
              value={params.roleAssignments}
            />
          </Progress>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
