import React, { useMemo } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { customValidator } from 'core/utils/fieldValidators'

interface Props {
  values: any
  setFieldValue: any
  groups: any[]
  groupId?: string
}

const GroupSettingsFields = ({ values, setFieldValue, groups, groupId }: Props) => {
  const nameValidator = useMemo(() => {
    return customValidator((name) => {
      return !groups.some((group) => {
        return groupId ? group.id !== groupId && group.name === name : group.name === name
      })
    }, `Another group already has this name.`)
  }, [groups, groupId])

  return (
    <>
      <TextField
        id="name"
        label="Name"
        onChange={setFieldValue('name')}
        value={values.name}
        validations={[nameValidator]}
        required
      />
      <TextField
        id="description"
        label="Description"
        onChange={setFieldValue('description')}
        value={values.description}
        required
      />
      <TextField
        id="firstNameKey"
        label="SAML Attribute Key for a User's First Name"
        onChange={setFieldValue('firstNameKey')}
        value={values.firstNameKey}
        info="The SAML Attribute Key defined to capture a SAML user's first name in your Identity Provider. This field, along with the 'last name' field is used to identify a user when they log in."
        required
      />
      <TextField
        id="lastNameKey"
        label="SAML Attribute Key for a User's Last Name"
        onChange={setFieldValue('lastNameKey')}
        value={values.lastNameKey}
        info="The SAML Attribute Key defined to capture a SAML user's last name in your Identity Provider. This field, along with the 'first name' field is used to identify a user when they log in."
        required
      />
      <TextField
        id="emailKey"
        label="SAML Attribute Key for a User's Email"
        onChange={setFieldValue('emailKey')}
        value={values.emailKey}
        info="The SAML Attribute Key defined to capture a SAML user's email in your Identity Provider. This field, is used to identify a user when they log in."
        required
      />
    </>
  )
}

export default GroupSettingsFields
