import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React, { useCallback, useMemo, useState } from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { join, pick, pipe, pluck, prop } from 'ramda'
import { usersSelector } from './selectors'
import { listUsers } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import ListContainer from 'core/containers/ListContainer'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { routes } from 'core/utils/routes'
import { arrayIfNil } from 'utils/fp'
import EnableDisableUserDialog from './enable-disable-user-dialog'
import UserDeleteDialog from './delete-user-dialog'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { useSelector } from 'react-redux'
import CreateUserModal from './CreateUserModal'
import EditUserModal from './EditUserModal'
import UserQuotasModal from './UserQuotasModal'

type ModelDataKey = DataKeys.ManagementUsers
type SelectorModel = ArrayElement<ReturnType<typeof usersSelector>>

const defaultParams = {}
const usePrefParams = createUsePrefParamsHook('ManagementUsers', listTablePrefs)

const isEnabledUser = (user) => user?.enabled

const searchTargets = ['username', 'id', 'displayname']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'User Uuid', display: false },
  { key: 'username', label: 'Username' },
  { key: 'displayname', label: 'Display Name' },
  // { key: 'twoFactor', label: 'Two-Factor Authentication' },
  // { key: 'isLocal', label: 'Type', formatFn: (val: boolean) => (val ? 'Platform9' : 'SSO') },
  { key: 'enabled', label: 'Status', formatFn: (val: boolean) => (val ? 'Enabled' : 'Disabled') },
  // { key: 'tenants', label: 'Tenants', formatFn: pipe(arrayIfNil, pluck('name'), join(', ')) },
]

export default function UsersListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listUsers)
  const [selectedUser, setSelectedUser] = useState<SelectorModel>(null)
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false)
  const [showUserQuotasModal, setShowUserQuotasModal] = useState<boolean>(false)

  const data = useSelectorWithParams(usersSelector, params)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))

  const isActiveUser = useCallback(
    (user) => {
      return session?.username === user?.username
    },
    [session],
  )

  const batchActions = useMemo(
    () => [
      {
        // Todo: Revesit this condition after SSO is enabled
        // cond: ([user]) => user?.isLocal,
        label: 'Edit',
        icon: 'edit',
        handleAction: ([user]) => {
          setSelectedUser(user)
          setShowEditUserModal(true)
        },
        refreshAfterSuccess: true,
      },
      {
        label: 'Manage Quotas',
        icon: 'chart-pie-simple',
        handleAction: ([user]) => {
          setSelectedUser(user)
          setShowUserQuotasModal(true)
        },
        refreshAfterSuccess: true,
      },
      {
        cond: ([user]) => !isActiveUser(user) && !isEnabledUser(user),
        label: 'Enable',
        icon: 'user-check',
        BatchActionButton: getGridDialogButton(EnableDisableUserDialog),
      },
      {
        cond: ([user]) => !isActiveUser(user) && isEnabledUser(user),
        label: 'Disable',
        icon: 'user-times',
        BatchActionButton: getGridDialogButton(EnableDisableUserDialog),
      },
      {
        cond: ([user]) => !isActiveUser(user),
        label: 'Delete',
        icon: 'trash-alt',
        BatchActionButton: getGridDialogButton(UserDeleteDialog),
      },
    ],
    [isActiveUser],
  )

  return (
    <>
      <DocumentMeta title="Users" />
      <CreateUserModal addRoute={routes.openstack.userManagement.addUser} />
      {showEditUserModal && (
        <EditUserModal onClose={() => setShowEditUserModal(false)} rows={[selectedUser]} />
      )}
      {showUserQuotasModal && (
        <UserQuotasModal onClose={() => setShowUserQuotasModal(false)} rows={[selectedUser]} />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ManagementUsers}
        searchTargets={searchTargets}
        nameProp="username"
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.openstack.userManagement.addUser.path()}
        addText="Create a New User"
        getParamsUpdater={getParamsUpdater}
        // editUrl={(_, id) => routes.openstack.userManagement.editUser.path({ id })}
        // editCond={([selectedRow]) => selectedRow?.isLocal}
        // This editDisabledInfo tooltip does not show up
        // editDisabledInfo={`You may not edit SSO users. To change an SSO user's roles you may edit the SAML Group that they are a member of.`}
        // deleteAction={deleteUser}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
