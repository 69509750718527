import { createSelector } from '@reduxjs/toolkit'
import { pipe } from 'ramda'
import { filterIf } from 'utils/fp'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { IRolesSelector } from './model'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'

const whitelistedRoles = ['admin', 'member', 'reader']

export const managementRolesSelector = createSharedSelector(
  getDataSelector<DataKeys.ManagementRoles>(DataKeys.ManagementRoles),
  (rawRoles) => {
    // associate nodes with the combinedHost entry
    return rawRoles
      .filter((role) => whitelistedRoles.includes(role.name))
      .map((role) => ({
        ...role,
        displayName:
          role.displayName || validOverrides.includes(role.name)
            ? hardcodedRolesNames[role.name]
            : role.name,
        description: validOverrides.includes(role.name)
          ? hardcodedRolesDescriptions[role.name]
          : role.description,
      }))
  },
)

export const makeManagementRolesSelector = (
  defaultParams = {
    orderBy: 'name',
    orderDirection: 'desc',
  } as SortConfig & { allRoles?: boolean },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(managementRolesSelector, selectParams, (roles, params) => {
    const { allRoles, orderBy, orderDirection } = params
    return pipe<IRolesSelector[], IRolesSelector[], IRolesSelector[]>(
      filterIf(!allRoles, (role) => validOverrides.includes(role.name)),
      createSorter({ orderBy, orderDirection }),
    )(roles)
  })
}

// Generally not a fan of this, but we dont have a lot of options.
// Need to update the backend database records to have an actual description / friendly name
const hardcodedRolesDescriptions = {
  admin:
    'An Administrator has global access to all resources within the cloud, and access to perform all operations.',
  member:
    'A Self-service user has limited access to the cloud. He can create new instances using Images, Flavors and Networks he has access to. Instances are created on the infrastructure assigned to the Tenant that the Self-service user is a member of.',
  reader: 'A read-only role grants limited access to only view resources in the cloud.',
}

const hardcodedRolesNames = {
  admin: 'Administrator',
  member: 'Self-service User',
  reader: 'Read Only User',
}
const validOverrides = Object.keys(hardcodedRolesNames)
