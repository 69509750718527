import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getComputeQuotas, getCinderQuotas, getNetworkQuotas, updateQuotas } from './new-actions'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Tab from 'core/elements/tabs/Tab'
import Tabs from 'core/elements/tabs'

const useStyles = makeStyles<Theme>((theme) => ({
  checkboxRow: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: 16,
    alignItems: 'center',
  },
  checkbox: {
    position: 'relative',
    top: 14,
  },
  fields: {
    marginLeft: 24,
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
}))

interface Props {
  rows: any[]
  onClose: () => void
}

const convertValue = (number, unit) => {
  if (unit === 'MiB') {
    if (number >= 1024) {
      const convertedNumber = number / 1024
      return convertValue(convertedNumber, 'GiB')
    } else {
      return { number: parseFloat(number.toFixed(2)), unit }
    }
  } else if (unit === 'GiB') {
    if (number >= 1024) {
      const convertedNumber = number / 1024
      return convertValue(convertedNumber, 'TiB')
    } else {
      return { number: parseFloat(number.toFixed(2)), unit }
    }
  } else if (unit === 'TiB') {
    return { number: parseFloat(number.toFixed(2)), unit }
  }
}

const WithUnlimited = ({ children, property, params, updateParams }) => {
  const classes = useStyles()
  const [originalValue, setOriginalValue] = useState(-1)
  useEffect(() => {
    if (params[property] !== '' && params[property] !== -1) {
      setOriginalValue(params[property])
    }
  }, [params[property]])
  return (
    <div className={classes.checkboxRow}>
      {children}
      <CheckboxField
        className={classes.checkbox}
        id={`${property}_unlimited`}
        label="Set Unlimited"
        value={params[property] === -1 ? true : false}
        onChange={(value) => {
          if (value) {
            updateParams({ [property]: -1 })
          } else {
            updateParams({ [property]: originalValue })
          }
        }}
      />
    </div>
  )
}

export default function TenantQuotasModal({ rows: [tenant], onClose }: Props) {
  const classes = useStyles()
  const defaultParams = {
    cores: null,
    ram: null,
    instances: null,
    blockStorage: null,
    volumes: null,
    volumeSnapshots: null,
    maxVolumeSize: null,
    networks: null,
    subnets: null,
    routers: null,
    ports: null,
    floatingIps: null,
    securityGroups: null,
    securityGroupRules: null,
  }
  const [activeTab, setActiveTab] = useState('compute')
  const [dataLoaded, setDataLoaded] = useState(false)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateQuotas)

  useEffect(() => {
    const makeCalls = async () => {
      const response = await getComputeQuotas({ tenantId: tenant.id })
      const cinderQuotas = await getCinderQuotas({ tenantId: tenant.id })
      const networkQuotas = await getNetworkQuotas({ tenantId: tenant.id })
      updateParams({
        cores: response.cores,
        ram: response.ram,
        instances: response.instances,
        blockStorage: cinderQuotas.gigabytes,
        volumes: cinderQuotas.volumes,
        volumeSnapshots: cinderQuotas.snapshots,
        maxVolumeSize: cinderQuotas.per_volume_gigabytes,
        networks: networkQuotas.network,
        subnets: networkQuotas.subnet,
        routers: networkQuotas.router,
        ports: networkQuotas.port,
        floatingIps: networkQuotas.floatingip,
        securityGroups: networkQuotas.security_group,
        securityGroupRules: networkQuotas.security_group_rule,
      })
      setDataLoaded(true)
    }
    makeCalls()
  }, [])

  const submitForm = useCallback(async () => {
    const quotas = {
      computeBody: {
        quota_set: {
          cores: params.cores,
          ram: params.ram,
          instances: params.instances,
        },
      },
      storageBody: {
        quota_set: {
          gigabytes: params.blockStorage,
          volumes: params.volumes,
          snapshots: params.volumeSnapshots,
          per_volume_gigabytes: params.maxVolumeSize,
        },
      },
      networkingBody: {
        quota: {
          network: params.networks,
          subnet: params.subnets,
          router: params.routers,
          port: params.ports,
          floatingip: params.floatingIps,
          security_group: params.securityGroups,
          security_group_rule: params.securityGroupRules,
        },
      },
    }
    const { success } = await update({ tenantId: tenant.id, quotas })
    if (success) {
      handleClose()
    }
  }, [tenant?.id, params])

  const handleClose = () => {
    setParams(defaultParams)
    setDataLoaded(false)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Quotas for Tenant ${tenant.name}`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={!dataLoaded}
      submitting={updating}
      error={error}
      submitTitle={`Update Tenant Quotas`}
      open
    >
      <Tabs activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)}>
        <Tab label="Compute Quotas" value="compute">
          <div className={classes.fields}>
            <WithUnlimited property="cores" params={params} updateParams={updateParams}>
              <TextField
                id="cores"
                label="Cores"
                onChange={getParamsUpdater('cores')}
                value={params.cores}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="ram" params={params} updateParams={updateParams}>
              <TextField
                id="ram"
                label="RAM (MiB)"
                onChange={getParamsUpdater('ram')}
                value={params.ram}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="instances" params={params} updateParams={updateParams}>
              <TextField
                id="instances"
                label="VMs"
                onChange={getParamsUpdater('instances')}
                value={params.instances}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
          </div>
        </Tab>
        <Tab label="Block Storage Quotas" value="blockStorage">
          <div className={classes.fields}>
            <WithUnlimited property="blockStorage" params={params} updateParams={updateParams}>
              <TextField
                id="blockStorage"
                label="Total Block Storage (GB)"
                onChange={getParamsUpdater('blockStorage')}
                value={params.blockStorage}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="volumes" params={params} updateParams={updateParams}>
              <TextField
                id="volumes"
                label="Volumes"
                onChange={getParamsUpdater('volumes')}
                value={params.volumes}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="volumeSnapshots" params={params} updateParams={updateParams}>
              <TextField
                id="volumeSnapshots"
                label="Volume Snapshots"
                onChange={getParamsUpdater('volumeSnapshots')}
                value={params.volumeSnapshots}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="maxVolumeSize" params={params} updateParams={updateParams}>
              <TextField
                id="maxVolumeSize"
                label="Max Volume Size (GB)"
                onChange={getParamsUpdater('maxVolumeSize')}
                value={params.maxVolumeSize}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
          </div>
        </Tab>
        <Tab label="Network Quotas" value="network">
          <div className={classes.fields}>
            <WithUnlimited property="networks" params={params} updateParams={updateParams}>
              <TextField
                id="networks"
                label="Networks"
                onChange={getParamsUpdater('networks')}
                value={params.networks}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="subnets" params={params} updateParams={updateParams}>
              <TextField
                id="subnets"
                label="Subnets"
                onChange={getParamsUpdater('subnets')}
                value={params.subnets}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="routers" params={params} updateParams={updateParams}>
              <TextField
                id="routers"
                label="Routers"
                onChange={getParamsUpdater('routers')}
                value={params.routers}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="ports" params={params} updateParams={updateParams}>
              <TextField
                id="ports"
                label="Ports"
                onChange={getParamsUpdater('ports')}
                value={params.ports}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="floatingIps" params={params} updateParams={updateParams}>
              <TextField
                id="floatingIps"
                label="Public IPs"
                onChange={getParamsUpdater('floatingIps')}
                value={params.floatingIps}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited property="securityGroups" params={params} updateParams={updateParams}>
              <TextField
                id="securityGroups"
                label="Security Groups"
                onChange={getParamsUpdater('securityGroups')}
                value={params.securityGroups}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
            <WithUnlimited
              property="securityGroupRules"
              params={params}
              updateParams={updateParams}
            >
              <TextField
                id="securityGroupRules"
                label="Security Group Rules"
                onChange={getParamsUpdater('securityGroupRules')}
                value={params.securityGroupRules}
                type="number"
                min={-1}
                required
              />
            </WithUnlimited>
          </div>
        </Tab>
      </Tabs>
    </ModalForm>
  )
}
