import MyAccountHeader from 'account/components/secondaryHeaders/MyAccountHeader'
import SsoManagementPage from 'account/components/ssoManagement/SsoManagementPage'
import { AppPlugins, userAccountPrefix } from 'app/constants'
import Plugin from 'core/plugins/plugin'
import React from 'react'
import SystemStatusPage from './components/system-status'
import CustomThemePage from './components/theme/CustomThemePage'
import UserSettingsIndexPage from './components/user-settings/user-settings-index-page'

const myAccountPlugin = new Plugin(AppPlugins.MyAccount, 'Settings', userAccountPrefix, 'cog')
myAccountPlugin.registerSecondaryHeader(MyAccountHeader)

myAccountPlugin.registerRoutes([
  {
    name: 'System Status',
    link: {
      path: '/status',
      exact: true,
    },
    component: SystemStatusPage,
  },
  {
    name: 'User Settings',
    link: {
      path: '/settings/:tab?',
      exact: true,
      default: true,
      defaultParams: { tab: 'profile' },
    },
    component: UserSettingsIndexPage,
  },
  {
    name: 'Enterprise SSO',
    requiredRoles: 'admin',
    link: {
      path: '/sso/:tab?/(edit||add)?/:id?',
      exact: true,
    },
    component: SsoManagementPage,
  },
  {
    name: 'Custom Theme',
    requiredRoles: 'admin',
    link: { path: '/theme', exact: true },
    component: CustomThemePage,
  },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'User Settings',
    link: { path: '/settings/profile', definition: '/settings/:tab' },
    icon: 'users-cog',
  },
  // {
  //   name: 'Tenants & Users',
  //   link: { path: '/access/users', definition: '/access/:tab' },
  //   icon: 'users',
  //   requiredRoles: 'admin',
  //   nestedLinks: [
  //     {
  //       name: 'Tenants',
  //       link: { path: '/access/tenants' },
  //     },
  //     {
  //       name: 'Users',
  //       link: { path: '/access/users' },
  //     },
  //     { name: 'Groups', link: { path: '/access/groups' } },
  //     { name: 'Roles', link: { path: '/access/roles' } },
  //   ],
  // },
  {
    name: 'Enterprise SSO',
    link: { path: '/sso/configure', definition: '/sso/:tab' },
    icon: 'key',
    requiredRoles: 'admin',
    requiredFeatures: (features) => {
      // if (!features || !features.experimental) {
      //   return false
      // }
      // // Legacy DU & DDU have different conditions
      // if (features.experimental.kplane) {
      //   // Show user the upgrade dialog to FT users on the SSO screen itself
      //   return true
      // }
      // return features.experimental.sso
      return true
    },
  },
  {
    name: 'Custom Theme',
    link: { path: '/theme' },
    icon: 'palette',
    requiredRoles: 'admin',
    requiredFeatures: (features) => {
      return false
      // if (!features || !features.experimental) {
      //   return false
      // }
      // return features.experimental.kplane
    },
  },
]

myAccountPlugin.registerNavItems(navItems)

export default myAccountPlugin
