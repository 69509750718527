import { prop, pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { tenantsSelector } from './selectors'
import { listTenants, deleteTenant } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { Tenant } from 'api-client/keystone.model'
import CreateTenantModal from './CreateTenantModal'
import EditTenantModal from './EditTenantModal'
import TenantQuotasModal from './TenantQuotasModal'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { ITenantDetailsPageTabs } from './model'

type ModelDataKey = DataKeys.ManagementTenants
type SelectorModel = Tenant

const isNotServiceTenant = (tenants) => !tenants.find((t) => t.name === 'service')

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('Tenants', listTablePrefs)

const searchTargets = ['name']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'Tenant ID', display: false },
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.userManagement.tenantDetails.path({
          id,
          tab: ITenantDetailsPageTabs.Overview,
        }),
    }),
  },
  { key: 'description', label: 'Description' },
]

export default function TenantsListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listTenants)
  const [selectedTenant, setSelectedTenant] = useState<SelectorModel>(null)
  const [showEditTenantModal, setShowEditTenantModal] = useState<boolean>(false)
  const [showTenantQuotasModal, setShowTenantQuotasModal] = useState<boolean>(false)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'

  const data = useSelectorWithParams(tenantsSelector, params)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      label: 'Edit',
      icon: 'edit',
      handleAction: ([tenant]) => {
        setSelectedTenant(tenant)
        setShowEditTenantModal(true)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: ([tenant]) => {
        return isVmware ? !!tenant && tenant?.name !== 'service' : !!tenant
      },
      label: 'Manage Quotas',
      icon: 'chart-pie-simple',
      handleAction: ([tenant]) => {
        setSelectedTenant(tenant)
        setShowTenantQuotasModal(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Tenants" />
      <CreateTenantModal addRoute={routes.openstack.userManagement.addTenant} />
      {showEditTenantModal && (
        <EditTenantModal onClose={() => setShowEditTenantModal(false)} rows={[selectedTenant]} />
      )}
      {showTenantQuotasModal && (
        <TenantQuotasModal
          onClose={() => setShowTenantQuotasModal(false)}
          rows={[selectedTenant]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ManagementTenants}
        searchTargets={searchTargets}
        nameProp="name"
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.openstack.userManagement.addTenant.path()}
        addText="Create a New Tenant"
        getParamsUpdater={getParamsUpdater}
        // editUrl={(_, id) => routes.openstack.userManagement.editTenant.path({ id })}
        deleteCond={isNotServiceTenant}
        // editCond={isNotServiceTenant}
        deleteAction={deleteTenant}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
