import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { sessionActions, SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import { mngmUserActions, updateUserPassword } from '../userManagement/users/actions'
import UserPasswordField from '../userManagement/users/UserPasswordField'
import DocumentMeta from 'core/components/DocumentMeta'
import Avatar from 'core/components/Avatar'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import { useDispatch, useSelector } from 'react-redux'
import Progress from 'core/components/progress/Progress'
import { RootState } from 'app/store'
import { emailValidator } from 'core/utils/fieldValidators'
import useScopedPreferences from 'core/session/useScopedPreferences'
import TenantsAndRoleAccessTable from './tenants-and-role-access-table'
import { isAdminRole } from 'k8s/util/helpers'
import { setActiveRegion } from 'api-client/helpers'
import ThemeChoiceCard from './theme-choice-card'
import CurrentPlanCard from './current-plan-card'
import { CustomerTiers } from 'app/constants'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(4),
    gridTemplateColumns: '766px 766px',
    '@media (max-width:1920px)': {
      gridTemplateColumns: 'unset',
      maxWidth: 766,
      gridGap: 16,
      '& .current-plan-card': {
        order: -1,
      },
    },
  },
  form: {
    marginTop: theme.spacing(2),
  },
  passwordField: {
    '& .input[type=password]::-ms-reveal, & .input[type=password]::-ms-clear': {
      display: 'none',
    },
  },
  userDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  avatar: {
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
  },
  inputField: {
    width: '100% !important',
  },
  button: {
    gridColumn: '2',
    marginTop: theme.spacing(3),
    width: 'max-content',
  },
  passwordForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  confirmPasswordField: {
    gridColumn: '2',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}))

const MyProfilePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { prefs, updatePrefs } = useScopedPreferences()
  const [errorMessage, setErrorMessage] = useState('')
  const [oldUserPrefs, setOldUserPrefs] = useState(prefs)
  const [userUpdated, setUserUpdated] = useState(false)
  const userIdFormFieldSetter = useRef(null)
  const [updatingPassword, setUpdatingPassword] = useState(false)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features, userDetails, isSsoToken } = session
  const { id: userId, displayName, email } = userDetails
  const isAdmin = useMemo(() => isAdminRole(session), [session])

  const [update, updatingUser] = useDataUpdater(mngmUserActions.update)

  const userInfo = useMemo(
    () => ({
      id: userId,
      username: email,
      displayname: displayName || '',
      email,
    }),
    [displayName, email],
  )

  const loadingSomething = updatingUser || updatingPassword

  useEffect(() => {
    if (isSsoToken || !userIdFormFieldSetter.current) {
      return
    }
    // Reset the initial values for the user ID form with the new updated values
    userIdFormFieldSetter.current.setField('email')(email)
    userIdFormFieldSetter.current.setField('displayName')(displayName)
  }, [displayName, email, isSsoToken])

  useEffect(() => {
    // Whenever the user's email gets updated, transfer their old prefs over to the new email
    if (userUpdated) {
      updatePrefs(oldUserPrefs)
      if (oldUserPrefs.currentRegion) {
        setActiveRegion(oldUserPrefs.currentRegion)
      }
      setUserUpdated(false)
    }
  }, [email])

  const setupUserIdFieldSetter = (setField) => {
    userIdFormFieldSetter.current = { setField }
  }

  const handleUserIdUpdate = async (values) => {
    // Save the prefs under the old email to transfer to the new email
    setOldUserPrefs(prefs)

    const user = {
      ...userInfo,
      username: values.email || userInfo.email,
      displayname: values.displayname || userInfo.displayname,
    }
    const updatedUser = await update(user)

    if (updatedUser) {
      setUserUpdated(true)

      dispatch(
        sessionActions.updateSession({
          username: updatedUser.email,
          userDetails: {
            ...userDetails,
            username: updatedUser.email,
            name: updatedUser.email,
            email: updatedUser.email,
            displayName: updatedUser.displayname, // displayName is a UI variable
            displayname: updatedUser.displayname, // displayname is what we get from the api
          },
        }),
      )
    }
  }

  const handlePasswordUpdate = async ({ currentPassword, newPassword, confirmedPassword }) => {
    if (newPassword !== confirmedPassword) {
      setErrorMessage('New passwords do not match.')
      return
    }
    setErrorMessage('')
    setUpdatingPassword(true)
    const success = await updateUserPassword({ id: userId, email, currentPassword, newPassword })
    setUpdatingPassword(false)
    if (!success) {
      setErrorMessage('Unable to update password. Current password may be incorrect')
    }
  }

  return (
    <>
      <DocumentMeta title="User Settings" bodyClasses={['form-view']} />
      <Progress
        message={updatingUser || updatingPassword ? 'Updating user ...' : 'Loading user ...'}
        loading={loadingSomething}
      >
        <div className={classes.root}>
          <div>
            {!isSsoToken && (
              <ValidatedForm
                fullWidth
                initialValues={userInfo}
                onSubmit={handleUserIdUpdate}
                fieldSetter={setupUserIdFieldSetter}
                elevated={false}
              >
                <FormFieldCard>
                  <div className={classes.userDetails}>
                    <div className={classes.fields}>
                      <TextField
                        className={classes.inputField}
                        id="email"
                        label="Email"
                        validations={[emailValidator]}
                        disabled={!isAdmin}
                      />
                      <TextField
                        className={classes.inputField}
                        id="displayname"
                        label="Display Name"
                        disabled={!isAdmin}
                      />
                      {isAdmin && <SubmitButton className={classes.button}>Update</SubmitButton>}
                    </div>
                    <div className={classes.avatar}>
                      <Avatar displayName={displayName} diameter={120} fontSize={24} />
                    </div>
                  </div>
                </FormFieldCard>
              </ValidatedForm>
            )}
            {!isSsoToken && (
              <ValidatedForm
                classes={{ root: classes.form }}
                onSubmit={handlePasswordUpdate}
                clearOnSubmit
                elevated={false}
              >
                {({ values }) => (
                  <FormFieldCard>
                    <div className={classes.passwordForm}>
                      <UserPasswordField
                        id="currentPassword"
                        label="Current Password"
                        value={values.currentPassword}
                        showPasswordRequirements={false}
                        restrictPassword={false}
                        className={classes.passwordField}
                      />
                      <UserPasswordField
                        id="newPassword"
                        label="New Password"
                        className={classes.passwordField}
                        value={values.newPassword}
                      />
                      <UserPasswordField
                        id="confirmedPassword"
                        label="Confirm New Password"
                        value={values.confirmedPassword}
                        showPasswordRequirements={false}
                        className={classes.passwordField}
                      />
                      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                      <SubmitButton className={classes.button}>Update</SubmitButton>
                    </div>
                  </FormFieldCard>
                )}
              </ValidatedForm>
            )}
            {/* <FormFieldCard className={classes.form} title="Themes">
              <ThemeChoiceCard />
            </FormFieldCard> */}
            {isAdmin && (
              <FormFieldCard className={classes.form} title="Tenants & Role Access">
                <TenantsAndRoleAccessTable userId={userId} />
              </FormFieldCard>
            )}
          </div>
          {/* {features?.customer_tier !== CustomerTiers.OEM && (
            <div className="current-plan-card">
              <CurrentPlanCard />
            </div>
          )} */}
        </div>
      </Progress>
    </>
  )
}

export default MyProfilePage
