import React, { useCallback, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createUser } from './new-actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TenantRolesTableField from 'account/components/userManagement/users/TenantRolesTableField'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { emailValidator, requiredValidator } from 'core/utils/fieldValidators'
import RadioFields, { Orientation } from 'core/components/validatedForm/radio-fields'
import UserPasswordField from 'account/components/userManagement/users/UserPasswordField'
import Progress from 'core/components/progress/Progress'
import { getDomainId } from 'api-client/helpers'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

export const accountActivationRadioOptions = [
  {
    value: 'activationByEmail',
    label: 'Send activation email to the user.',
    info:
      ' Instructions to create a new password and to activate account will be sent to the email provided.',
  },
  {
    value: 'createPassword',
    label: 'Set password for new user now.',
    info: 'Create password for the new user now and activate the account immediately.',
  },
]

export default function CreateUserModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    username: '',
    displayname: '',
    password: '',
    roleAssignments: {},
  }
  const [activationType, setActivationType] = useState('createPassword')

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { update, updating, error, reset } = useUpdateAction(createUser)

  const submitForm = useCallback(async () => {
    const body = {
      username: params.username,
      displayname: params.displayname,
      password: params.password,
      roleAssignments: params.roleAssignments,
      activationType: activationType,
      domain_id: getDomainId() || 'default',
    }
    const { success } = await update(body)
    if (success) {
      handleClose()
    }
  }, [params, activationType])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.userManagement.users.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create User`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      initialValues={defaultParams}
      submitTitle={`Create User`}
    >
      <>
        <FormFieldSection title="User Settings">
          <TextField
            id="username"
            label="Email"
            onChange={getParamsUpdater('username')}
            value={params.username}
            validations={[emailValidator]}
            required
          />
          <TextField
            id="displayname"
            label="Display Name"
            onChange={getParamsUpdater('displayname')}
            value={params.displayname}
          />
          {/* <RadioFields
            id="activationType"
            orientation={Orientation.Row}
            title="Activate User Account"
            options={accountActivationRadioOptions}
            value={activationType}
            onChange={(type: string) => setActivationType(type)}
          /> */}
          {activationType === 'createPassword' && (
            <UserPasswordField value={params.password} onChange={getParamsUpdater('password')} />
          )}
          <Progress
            renderContentOnMount={!loadingTenants}
            loading={loadingTenants}
            message={'Loading Tenants...'}
          >
            <TenantRolesTableField
              validations={tenantRolesValidations}
              id="roleAssignments"
              tenants={tenants}
              onChange={getParamsUpdater('roleAssignments')}
            />
          </Progress>
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
